<template>
	<div class="header" :class="scrolled ? 'scrolled' : ''">
		<div class="wrap">
			<header class="l-header" data-scroll-section>
				<router-link :to="{ name: 'home' }">
						<!-- <ImageItem class="l-header__logo" :source="require(`@/assets/logo.svg`)" alt="logo"/>
             -->
						<img  class="l-header__logo" @click="scrollToHome()" src="@/assets/logo.svg" alt="logo" />
				</router-link>
				<button
					class="l-header__burger"
					:class="menuIsOpen ? '--active' : ''"
					@click="menuIsOpen ? close() : open()"
				>
					<div class="l-header__burger-top"></div>
					<div class="l-header__burger-bottom"></div>
					<div class="l-header__burger-center "></div>
				</button>
			</header>
		</div>
		<transition name="menu-fade">
			<TheMenu
				@close="close"
				@open="open"
				v-if="menuIsOpen"
				:menuIsOpen="menuIsOpen"
				@go-to-contact="goToContact"
			/>
		</transition>
	</div>
</template>

<script>
import TheMenu from "@/components/TheMenu";
// import ImageItem from "@/components/ImageItem";

export default {
	name: "TheHeader",
	components: {
		TheMenu,
		// ImageItem,
	},
	data() {
		return {
			menuIsOpen: false,
			scrolled: false,
		};
	},
	methods: {
		open() {
			this.menuIsOpen = true;
		},
		close() {
			this.menuIsOpen = false;
		},
		goToContact() {
			this.$emit("go-to-contact");
		},
		scrollToHome() {
			this.$emit("scroll-to", 0);
		},
	},
};
</script>
