<template>
	<div id="app">
		<TheHeader
			v-show="isPortrait"
			ref="TheHeader"
			@scroll-to="appScrollTo"
			@go-to-contact="goToContact"
		/>
		<div id="locomotive-scroll" data-scroll-container>
			<div id="wrap" class="wrap height100vh">
				<transition name="fade">
					<!-- prettier-ignore -->
					<router-view
            @view-mounted="viewMounted"
            @scroll-to="appScrollTo"
            @stop-scrolling="stopScrolling"
            @keep-scrolling="keepScrolling"
            @more-content="moreContent"
            @more-comments="moreComments"
						@is-portrait="isPortrait"
          />
				</transition>
				<TheFooter />
			</div>
			<TheFooterBackground v-if="!isLoading" />
			<Backgrounds v-if="!isLoading" />
		</div>
		<Particles id="tsparticles" :options="particlesOptions" v-if="!isLoading" />
		<div id="lock" v-show="!isPortrait" class="lock">
			<img
				src="./assets/landscape-blocker.gif"
				alt="rotate portrait"
				class="lock__gif"
			/>
			<h3 class="lock__title">
				Please rotate your device
			</h3>
			<p class="lock__text">
				For a better experience we use Portrait mode only
			</p>
		</div>
	</div>
</template>

<script>
import locomotiveScroll from "locomotive-scroll";
import imagesLoaded from "imagesloaded";

import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheFooterBackground from "@/components/TheFooterBackground";
import Backgrounds from "@/components/Backgrounds.vue";
import ShareImage from "@/assets/share.jpg";

import particlesOptions from "@/js/particlesOptions";
import { debounce } from "lodash";

export default {
	name: "App",
	metaInfo() {
		return {
			title: "Ascentador",
			meta: [
				{
					name: "description",
					content:
						"At the heart of all our work is understanding fueled by our proprietary Diagnostic Process. Marrying a powerful intuitive capacity with validated tools for organizational, individual and management assessment, this approach often uncovers what other eyes have missed.",
				},
				{ property: "og:title", content: "Ascentador" },
				{ property: "og:site_name", content: "Ascentador" },
				{ property: "og:url", content: window.location.href },
				{ property: "og:image", content: ShareImage },
				{ property: "og:type", content: "website" },
				{ name: "robots", content: "index,follow" },
			],
		};
	},
	components: {
		TheHeader,
		TheFooter,
		TheFooterBackground,
		Backgrounds,
	},
	data() {
		return {
			color: "green",
			isLoading: true,
			isPortrait: false,
			particlesOptions: particlesOptions,
		};
	},
	methods: {
		setResposive() {
			let landscape = document.getElementById("lock");
			let wrap = document.getElementById("wrap");
			// Landscape 375x667
			if (
				window.matchMedia("(orientation: landscape)").matches &&
				window.innerWidth >= 500 &&
				window.innerWidth < 980
			) {
				landscape.classList.add("landscape");
				wrap.classList.add("landscape");
				this.isPortrait = false;
				this.locomotive.stop();
			}
			// Portrait
			else {
				landscape.classList.remove("landscape");
				wrap.classList.remove("landscape");
				this.isPortrait = true;
				this.locomotive.start();
				this.locomotive.update();
			}
		},
		appScrollTo(target, offset) {
			this.locomotive.scrollTo(target, {
				offset: offset,
				callback: () => {
					this.locomotive.update();
				},
			});
		},
		goToContact() {
			if (this.$route.name == "home") {
				this.$refs.TheHeader.close();
				this.locomotive.scrollTo("#contact");
			}
			// Not In Home
			else {
				this.$router.push({ name: "home", hash: "#contact" });
			}
		},
		viewMounted() {
			this.$refs.TheHeader.close();
			this.isLoading = false;
			if (!this.$route.hash) this.appScrollTo(0);
			else if (this.$route.hash == "#contact") {
				setTimeout(() => {
					this.locomotive.update();
					this.locomotive.scrollTo("#contact");
				}, 1000);
			}
		},
		stopScrolling() {
			this.locomotive.stop();
		},
		keepScrolling() {
			this.locomotive.start();
		},

		moreContent() {
			this.appScrollTo("bottom");
		},
		moreComments() {
			this.locomotive.update();
		},
	},
	// created() {

	// 	// window.addEventListener("resize", debounce(this.setResposive, 200));
	// },
	mounted() {
		this.locomotive = new locomotiveScroll({
			el: document.querySelector("[data-scroll-container]"),
			smooth: true,
			smartphone: {
				smooth: true,
				multiplier: 2,
			},
			tablet: {
				smooth: true,
				multiplier: 2,
			},
		});
		this.locomotive.on("scroll", (args) => {
			if (args.scroll.y) {
				this.$refs.TheHeader.scrolled = true;
			} else {
				this.$refs.TheHeader.scrolled = false;
			}
		});
		imagesLoaded(document.querySelector("body"), () => {
			const inViewEls = document.querySelectorAll(".is-inview");
			inViewEls.forEach((item) => {
				item.classList.remove("is-inview");
			});
			this.locomotive.update();
		});
		this.setResposive();
		window.addEventListener("resize", debounce(this.setResposive, 200));
	},
};
</script>
