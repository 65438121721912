import '@/js/vendors';

import Vue from 'vue';
import initRouter from './js/router';
import App from './App.vue';

import './scss/app.scss';

import lazyLoadDirective from './js/lazyLoadDirective';

Vue.directive('lazyload', lazyLoadDirective);
Vue.config.productionTip = false;

initRouter().then((router) => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');
});
