import axios from 'axios';

import Vue from 'vue';
import VueRouter from 'vue-router';

async function initRouter() {
  Vue.use(VueRouter);

  const routes = [
    {
      name: 'home',
      path: '/',
      component: () => import('@/pages/home/Home'),
    },
    {
      name: 'approach',
      path: '/approach',
      component: () => import('@/pages/approach/Approach'),
    },
    {
      name: 'exchange',
      path: '/exchange',
      component: () => import('@/pages/exchange/Exchange'),
    },
    {
      name: 'markets',
      path: '/markets',
      component: () => import('@/pages/markets/Markets'),
    },
    {
      name: 'work',
      path: '/work',
      redirect: { name: 'work.executive-recruitment' },
      component: () => import('@/pages/work/Work'),
      children: [
        {
          name: 'work.executive-recruitment',
          path: '/work/executive-recruitment',
          component: () => import('@/pages/work/ExecutiveRecruitment'),
        },
        {
          name: 'work.succession-planning',
          path: '/work/succession-planning',
          component: () => import('@/pages/work/SuccessionPlanning'),
        },
        {
          name: 'work.transaction-support',
          path: '/work/transaction-support',
          component: () => import('@/pages/work/TransactionSupport'),
        },
        {
          name: 'work.professional-development',
          path: '/work/professional-development',
          component: () => import('@/pages/work/ProfessionalDevelopment'),
        },
      ],
    },
    {
      name: 'journey',
      path: '/journey',
      component: () => import('@/pages/journey/Journey'),
    },

    // {
    //   name: 'error404',
    //   path: '*',
    //   component: () => import('@/pages/Error404'),
    // },
  ];

  const API = process.env.VUE_APP_API;

  await axios.get(`${API}/article?all=true`).then((res) => {
    const articles = res.data.data;
    articles.forEach((article) => {
      routes.push({
        name: `exchange.${article.slug}`,
        path: `/exchange/${article.slug}`,
        component: () => import('@/pages/exchange/ExchangeArticle'),
      });
    });
  });

  const router = new VueRouter({
    mode: 'history',
    routes,
  });

  return router;
}

export default initRouter;
